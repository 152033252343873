import { default as indexp0NnxoGkzmMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/browse/[category]/index.vue?macro=true";
import { default as indexUjobWznfuxMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/browse/components/index.ts?macro=true";
import { default as PageFiltersycJpq3oO7NMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/browse/components/PageFilters.vue?macro=true";
import { default as SectionCategoriesjKbXyVxNOKMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/browse/components/SectionCategories.vue?macro=true";
import { default as SectionCollectionListCUs36QXSrsMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/browse/components/SectionCollectionList.vue?macro=true";
import { default as SectionFiltersz2sTKdiZvQMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/browse/components/SectionFilters.vue?macro=true";
import { default as SectionGenresZLRuTb1sDDMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/browse/components/SectionGenres.vue?macro=true";
import { default as SectionMoviesW4pR6vRyTvMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/browse/components/SectionMovies.vue?macro=true";
import { default as SectionPopularHryaF7LwvXMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/browse/components/SectionPopular.vue?macro=true";
import { default as SectionSerieslVWv8lbBGMMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/browse/components/SectionSeries.vue?macro=true";
import { default as SkeletonFiltersmmHZZwSGVmMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/browse/components/SkeletonFilters.vue?macro=true";
import { default as SkeletonGenrestZLNzQINmhMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/browse/components/SkeletonGenres.vue?macro=true";
import { default as _91slug_93amEJCjw7VEMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/browse/genre/[slug].vue?macro=true";
import { default as indexCKzg5vL8vxMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/browse/index.vue?macro=true";
import { default as _91slug_93sORyWmxLh7Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/collections/[slug].vue?macro=true";
import { default as indexT8wAk7iTVDMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/collections/index.vue?macro=true";
import { default as copyrightUol4LpZbDGMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/copyright.vue?macro=true";
import { default as _91id_93PmcUFKrY7lMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/crew/[id].vue?macro=true";
import { default as indexMI6bC4j6wFMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/faq/index.vue?macro=true";
import { default as allPlDLv49uHRMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/favorites/all.vue?macro=true";
import { default as SectionBoughtc9c1xJ7IVNMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/favorites/components/SectionBought.vue?macro=true";
import { default as SectionContinuexQRF5aRMelMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/favorites/components/SectionContinue.vue?macro=true";
import { default as SectionFavoritesaLUjiDBvRcMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/favorites/components/SectionFavorites.vue?macro=true";
import { default as SectionViewed127PWjnkKeMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/favorites/components/SectionViewed.vue?macro=true";
import { default as continueRgwprSN7wiMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/favorites/continue.vue?macro=true";
import { default as index7FscZCAFtzMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/favorites/index.vue?macro=true";
import { default as purchasedaX0xWTEMSEMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/favorites/purchased.vue?macro=true";
import { default as viewedhftC9xzPBvMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/favorites/viewed.vue?macro=true";
import { default as holder_45policyHpB7ayiP3tMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/holder-policy.vue?macro=true";
import { default as SectionAdvantagesvfY3otwzNWMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/home/components/SectionAdvantages.vue?macro=true";
import { default as SectionBannerAtqNoQTZpiMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/home/components/SectionBanner.vue?macro=true";
import { default as SectionCollectionsUJe3bWfQnVMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/home/components/SectionCollections.vue?macro=true";
import { default as SectionContinuePLtbxlYuhwMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/home/components/SectionContinue.vue?macro=true";
import { default as SectionFilms57GfuTzRzbMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/home/components/SectionFilms.vue?macro=true";
import { default as SectionGenresjq8XXNXwXvMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/home/components/SectionGenres.vue?macro=true";
import { default as SectionHeadervYUBfjQq4XMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/home/components/SectionHeader.vue?macro=true";
import { default as SectionPopular3wo04uYFV8Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/home/components/SectionPopular.vue?macro=true";
import { default as SectionPremiersLotOc657mYMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/home/components/SectionPremiers.vue?macro=true";
import { default as SectionSeriesMUXvDICuzDMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/home/components/SectionSeries.vue?macro=true";
import { default as SectionSubscriptionsjRHquLXwuVMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/home/components/SectionSubscriptions.vue?macro=true";
import { default as SectionSummarizeMDZS0WU7s8Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/home/components/SectionSummarize.vue?macro=true";
import { default as SectionTopMoviesPLYe3yB0wcMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/home/components/SectionTopMovies.vue?macro=true";
import { default as SectionUpcoming8iNglfXHjbMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/home/components/SectionUpcoming.vue?macro=true";
import { default as IndexBaXNMvZ5q3Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/home/Index.vue?macro=true";
import { default as _91slug_93Uf853iK9L2Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/install/[slug].vue?macro=true";
import { default as InfoInstalltGWwTUwSumMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/install/components/InfoInstall.vue?macro=true";
import { default as TitleInstall8f1AiU3eL1Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/install/components/TitleInstall.vue?macro=true";
import { default as typesXGYdvckpbiMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/install/components/types.ts?macro=true";
import { default as useInstallfYzjsUovEEMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/install/components/useInstall.ts?macro=true";
import { default as filmcrewxlrD5uTCHsMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/movie/[slug]/filmcrew.vue?macro=true";
import { default as indexs3tfUFStiEMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/movie/[slug]/index.vue?macro=true";
import { default as MovieCollectionsmImb5TTwJIMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/movie/components/MovieCollections.vue?macro=true";
import { default as MovieCrewSBNixOHbdEMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/movie/components/MovieCrew.vue?macro=true";
import { default as MovieDescriptionVCznXlnfJeMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/movie/components/MovieDescription.vue?macro=true";
import { default as MovieHeaderpdFHzzOYa6Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/movie/components/MovieHeader.vue?macro=true";
import { default as MovieHeaderNoticeGHBLRI8H7aMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/movie/components/MovieHeaderNotice.vue?macro=true";
import { default as MovieHeaderSkeletonM8xygxuIKgMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/movie/components/MovieHeaderSkeleton.vue?macro=true";
import { default as MoviePlayButton4W2VI4UZWaMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/movie/components/MoviePlayButton.vue?macro=true";
import { default as MovieRelatedzzuxEdJWh8Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/movie/components/MovieRelated.vue?macro=true";
import { default as MovieSeriesWQ7M75ae7JMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/movie/components/MovieSeries.vue?macro=true";
import { default as MovieTrailersqx41ACT43LMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/movie/components/MovieTrailers.vue?macro=true";
import { default as MovieWatchOptionsIM0kN3iYDqMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/movie/components/MovieWatchOptions.vue?macro=true";
import { default as _91slug_93fVvOsN9RRfMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/news/[slug].vue?macro=true";
import { default as indexHehBxwAnOAMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/news/index.vue?macro=true";
import { default as novelty_45filmsYLmFzg1tBoMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/novelty-films.vue?macro=true";
import { default as novelty_45series62guV15fLEMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/novelty-series.vue?macro=true";
import { default as policy_45agreementc5c5ndu71SMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/policy-agreement.vue?macro=true";
import { default as policy_45copyright4rjXOwCJcNMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/policy-copyright.vue?macro=true";
import { default as policy_45holder3XgqxiLIUBMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/policy-holder.vue?macro=true";
import { default as policy_45privateZkLvMVVHQtMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/policy-private.vue?macro=true";
import { default as policy_45userZI0rInsniwMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/policy-user.vue?macro=true";
import { default as premiersofhdNZdigWMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/premiers.vue?macro=true";
import { default as private_45policyHuFYKdSUILMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/private-policy.vue?macro=true";
import { default as indexKnrHjTULzfMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/bonuses/index.vue?macro=true";
import { default as VChangeRecurrentCardModalnqg8NEtJ5SMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/components/modal/VChangeRecurrentCardModal.vue?macro=true";
import { default as VEditProfileDHiqUhiQI3Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/components/modal/VEditProfile.vue?macro=true";
import { default as VRejectSubscribeModaldatZq9lf4NMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/components/modal/VRejectSubscribeModal.vue?macro=true";
import { default as VRemoveUserDatapq41XMcIwSMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/components/modal/VRemoveUserData.vue?macro=true";
import { default as VResumeSubscriptionSuccessModal7ZcDfBAhoJMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/components/modal/VResumeSubscriptionSuccessModal.vue?macro=true";
import { default as PersonalReferrerhDLvaTdl1aMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/components/PersonalReferrer.vue?macro=true";
import { default as validationibeKvk5s2BMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/components/validation.ts?macro=true";
import { default as VBonusHistoryCkdjjpuDRsMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/components/VBonusHistory.vue?macro=true";
import { default as VNotificationCardEHwFOVNjkaMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/components/VNotificationCard.vue?macro=true";
import { default as VNotificationsSettingsex9GNn59PrMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/components/VNotificationsSettings.vue?macro=true";
import { default as VPaymentsJvahL9da2JMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/components/VPayments.vue?macro=true";
import { default as VProfileTabssjzKYmWGQKMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/components/VProfileTabs.vue?macro=true";
import { default as VReferralProgramnuRc0Hd5rsMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/components/VReferralProgram.vue?macro=true";
import { default as VSubscriptionspgvEgDXGlNMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/components/VSubscriptions.vue?macro=true";
import { default as indexQnQaAsQvxFMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/devices/index.vue?macro=true";
import { default as indexuZLbqUW9jjMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/index.vue?macro=true";
import { default as indexlZwjzUdpDKMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/notifications/index.vue?macro=true";
import { default as indexHyuN1aJYIHMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/payments/index.vue?macro=true";
import { default as index24Bdhr0mkEMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/settings/index.vue?macro=true";
import { default as index79WDEaTBgNMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/user/index.vue?macro=true";
import { default as resultJ2Ln5Ptta6Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/result.vue?macro=true";
import { default as _91slug_93o1IHwYOwPBMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/similar/[slug].vue?macro=true";
import { default as indexAJGcbE9aczMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/sports/channels/index.vue?macro=true";
import { default as indexAkttmE9x3wMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/sports/index.vue?macro=true";
import { default as SectionAdsiJmexnNRF6Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/subscriptions/components/SectionAds.vue?macro=true";
import { default as SectionContent5LY0Ml3Vi3Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/subscriptions/components/SectionContent.vue?macro=true";
import { default as SectionDevicestuaxEtgOW9Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/subscriptions/components/SectionDevices.vue?macro=true";
import { default as SectionDownloadJd9CTawCo7Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/subscriptions/components/SectionDownload.vue?macro=true";
import { default as SectionHeaderFu9NzHTvEeMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/subscriptions/components/SectionHeader.vue?macro=true";
import { default as SectionSubscriptionsOBXPQtGReCMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/subscriptions/components/SectionSubscriptions.vue?macro=true";
import { default as SectionVoiceoverkYyv9muOprMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/subscriptions/components/SectionVoiceover.vue?macro=true";
import { default as indexc8GTEAoot6Meta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/subscriptions/index.vue?macro=true";
import { default as termsvgPlMmpZpYMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/terms.vue?macro=true";
import { default as upcominguAwFPNTjLlMeta } from "/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/upcoming.vue?macro=true";
export default [
  {
    name: "browse-category",
    path: "/browse/:category()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/browse/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: "browse-components",
    path: "/browse/components",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/browse/components/index.ts").then(m => m.default || m)
  },
  {
    name: "browse-components-PageFilters",
    path: "/browse/components/PageFilters",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/browse/components/PageFilters.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionCategories",
    path: "/browse/components/SectionCategories",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/browse/components/SectionCategories.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionCollectionList",
    path: "/browse/components/SectionCollectionList",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/browse/components/SectionCollectionList.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionFilters",
    path: "/browse/components/SectionFilters",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/browse/components/SectionFilters.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionGenres",
    path: "/browse/components/SectionGenres",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/browse/components/SectionGenres.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionMovies",
    path: "/browse/components/SectionMovies",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/browse/components/SectionMovies.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionPopular",
    path: "/browse/components/SectionPopular",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/browse/components/SectionPopular.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SectionSeries",
    path: "/browse/components/SectionSeries",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/browse/components/SectionSeries.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SkeletonFilters",
    path: "/browse/components/SkeletonFilters",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/browse/components/SkeletonFilters.vue").then(m => m.default || m)
  },
  {
    name: "browse-components-SkeletonGenres",
    path: "/browse/components/SkeletonGenres",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/browse/components/SkeletonGenres.vue").then(m => m.default || m)
  },
  {
    name: "browse-genre-slug",
    path: "/browse/genre/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/browse/genre/[slug].vue").then(m => m.default || m)
  },
  {
    name: "browse",
    path: "/browse",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/browse/index.vue").then(m => m.default || m)
  },
  {
    name: "collections-slug",
    path: "/collections/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/collections/[slug].vue").then(m => m.default || m)
  },
  {
    name: "collections",
    path: "/collections",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/collections/index.vue").then(m => m.default || m)
  },
  {
    name: "copyright",
    path: "/copyright",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/copyright.vue").then(m => m.default || m)
  },
  {
    name: "crew-id",
    path: "/crew/:id()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/crew/[id].vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "favorites-all",
    path: "/favorites/all",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/favorites/all.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionBought",
    path: "/favorites/components/SectionBought",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/favorites/components/SectionBought.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionContinue",
    path: "/favorites/components/SectionContinue",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/favorites/components/SectionContinue.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionFavorites",
    path: "/favorites/components/SectionFavorites",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/favorites/components/SectionFavorites.vue").then(m => m.default || m)
  },
  {
    name: "favorites-components-SectionViewed",
    path: "/favorites/components/SectionViewed",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/favorites/components/SectionViewed.vue").then(m => m.default || m)
  },
  {
    name: "favorites-continue",
    path: "/favorites/continue",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/favorites/continue.vue").then(m => m.default || m)
  },
  {
    name: "favorites",
    path: "/favorites",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/favorites/index.vue").then(m => m.default || m)
  },
  {
    name: "favorites-purchased",
    path: "/favorites/purchased",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/favorites/purchased.vue").then(m => m.default || m)
  },
  {
    name: "favorites-viewed",
    path: "/favorites/viewed",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/favorites/viewed.vue").then(m => m.default || m)
  },
  {
    name: "holder-policy",
    path: "/holder-policy",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/holder-policy.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionAdvantages",
    path: "/home/components/SectionAdvantages",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/home/components/SectionAdvantages.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionBanner",
    path: "/home/components/SectionBanner",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/home/components/SectionBanner.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionCollections",
    path: "/home/components/SectionCollections",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/home/components/SectionCollections.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionContinue",
    path: "/home/components/SectionContinue",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/home/components/SectionContinue.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionFilms",
    path: "/home/components/SectionFilms",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/home/components/SectionFilms.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionGenres",
    path: "/home/components/SectionGenres",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/home/components/SectionGenres.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionHeader",
    path: "/home/components/SectionHeader",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/home/components/SectionHeader.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionPopular",
    path: "/home/components/SectionPopular",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/home/components/SectionPopular.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionPremiers",
    path: "/home/components/SectionPremiers",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/home/components/SectionPremiers.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSeries",
    path: "/home/components/SectionSeries",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/home/components/SectionSeries.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSubscriptions",
    path: "/home/components/SectionSubscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/home/components/SectionSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionSummarize",
    path: "/home/components/SectionSummarize",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/home/components/SectionSummarize.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionTopMovies",
    path: "/home/components/SectionTopMovies",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/home/components/SectionTopMovies.vue").then(m => m.default || m)
  },
  {
    name: "home-components-SectionUpcoming",
    path: "/home/components/SectionUpcoming",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/home/components/SectionUpcoming.vue").then(m => m.default || m)
  },
  {
    name: "home-Index",
    path: "/home/Index",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/home/Index.vue").then(m => m.default || m)
  },
  {
    name: "install-slug",
    path: "/install/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/install/[slug].vue").then(m => m.default || m)
  },
  {
    name: "install-components-InfoInstall",
    path: "/install/components/InfoInstall",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/install/components/InfoInstall.vue").then(m => m.default || m)
  },
  {
    name: "install-components-TitleInstall",
    path: "/install/components/TitleInstall",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/install/components/TitleInstall.vue").then(m => m.default || m)
  },
  {
    name: "install-components-types",
    path: "/install/components/types",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/install/components/types.ts").then(m => m.default || m)
  },
  {
    name: "install-components-useInstall",
    path: "/install/components/useInstall",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/install/components/useInstall.ts").then(m => m.default || m)
  },
  {
    name: "movie-slug-filmcrew",
    path: "/movie/:slug()/filmcrew",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/movie/[slug]/filmcrew.vue").then(m => m.default || m)
  },
  {
    name: "movie-slug",
    path: "/movie/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/movie/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieCollections",
    path: "/movie/components/MovieCollections",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/movie/components/MovieCollections.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieCrew",
    path: "/movie/components/MovieCrew",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/movie/components/MovieCrew.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieDescription",
    path: "/movie/components/MovieDescription",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/movie/components/MovieDescription.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeader",
    path: "/movie/components/MovieHeader",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/movie/components/MovieHeader.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeaderNotice",
    path: "/movie/components/MovieHeaderNotice",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/movie/components/MovieHeaderNotice.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieHeaderSkeleton",
    path: "/movie/components/MovieHeaderSkeleton",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/movie/components/MovieHeaderSkeleton.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MoviePlayButton",
    path: "/movie/components/MoviePlayButton",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/movie/components/MoviePlayButton.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieRelated",
    path: "/movie/components/MovieRelated",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/movie/components/MovieRelated.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieSeries",
    path: "/movie/components/MovieSeries",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/movie/components/MovieSeries.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieTrailers",
    path: "/movie/components/MovieTrailers",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/movie/components/MovieTrailers.vue").then(m => m.default || m)
  },
  {
    name: "movie-components-MovieWatchOptions",
    path: "/movie/components/MovieWatchOptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/movie/components/MovieWatchOptions.vue").then(m => m.default || m)
  },
  {
    name: "news-slug",
    path: "/news/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/news/[slug].vue").then(m => m.default || m)
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "novelty-films",
    path: "/novelty-films",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/novelty-films.vue").then(m => m.default || m)
  },
  {
    name: "novelty-series",
    path: "/novelty-series",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/novelty-series.vue").then(m => m.default || m)
  },
  {
    name: "policy-agreement",
    path: "/policy-agreement",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/policy-agreement.vue").then(m => m.default || m)
  },
  {
    name: "policy-copyright",
    path: "/policy-copyright",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/policy-copyright.vue").then(m => m.default || m)
  },
  {
    name: "policy-holder",
    path: "/policy-holder",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/policy-holder.vue").then(m => m.default || m)
  },
  {
    name: "policy-private",
    path: "/policy-private",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/policy-private.vue").then(m => m.default || m)
  },
  {
    name: "policy-user",
    path: "/policy-user",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/policy-user.vue").then(m => m.default || m)
  },
  {
    name: "premiers",
    path: "/premiers",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/premiers.vue").then(m => m.default || m)
  },
  {
    name: "private-policy",
    path: "/private-policy",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/private-policy.vue").then(m => m.default || m)
  },
  {
    name: "profile-bonuses",
    path: "/profile/bonuses",
    meta: indexKnrHjTULzfMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/bonuses/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VChangeRecurrentCardModal",
    path: "/profile/components/modal/VChangeRecurrentCardModal",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/components/modal/VChangeRecurrentCardModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VEditProfile",
    path: "/profile/components/modal/VEditProfile",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/components/modal/VEditProfile.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VRejectSubscribeModal",
    path: "/profile/components/modal/VRejectSubscribeModal",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/components/modal/VRejectSubscribeModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VRemoveUserData",
    path: "/profile/components/modal/VRemoveUserData",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/components/modal/VRemoveUserData.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-modal-VResumeSubscriptionSuccessModal",
    path: "/profile/components/modal/VResumeSubscriptionSuccessModal",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/components/modal/VResumeSubscriptionSuccessModal.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-PersonalReferrer",
    path: "/profile/components/PersonalReferrer",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/components/PersonalReferrer.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-validation",
    path: "/profile/components/validation",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/components/validation.ts").then(m => m.default || m)
  },
  {
    name: "profile-components-VBonusHistory",
    path: "/profile/components/VBonusHistory",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/components/VBonusHistory.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VNotificationCard",
    path: "/profile/components/VNotificationCard",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/components/VNotificationCard.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VNotificationsSettings",
    path: "/profile/components/VNotificationsSettings",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/components/VNotificationsSettings.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VPayments",
    path: "/profile/components/VPayments",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/components/VPayments.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VProfileTabs",
    path: "/profile/components/VProfileTabs",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/components/VProfileTabs.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VReferralProgram",
    path: "/profile/components/VReferralProgram",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/components/VReferralProgram.vue").then(m => m.default || m)
  },
  {
    name: "profile-components-VSubscriptions",
    path: "/profile/components/VSubscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/components/VSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "profile-devices",
    path: "/profile/devices",
    meta: indexQnQaAsQvxFMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/devices/index.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexuZLbqUW9jjMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-notifications",
    path: "/profile/notifications",
    meta: indexlZwjzUdpDKMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-payments",
    path: "/profile/payments",
    meta: indexHyuN1aJYIHMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/payments/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-settings",
    path: "/profile/settings",
    meta: index24Bdhr0mkEMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-user",
    path: "/profile/user",
    meta: index79WDEaTBgNMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/profile/user/index.vue").then(m => m.default || m)
  },
  {
    name: "result",
    path: "/result",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/result.vue").then(m => m.default || m)
  },
  {
    name: "similar-slug",
    path: "/similar/:slug()",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/similar/[slug].vue").then(m => m.default || m)
  },
  {
    name: "sports-channels",
    path: "/sports/channels",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/sports/channels/index.vue").then(m => m.default || m)
  },
  {
    name: "sports",
    path: "/sports",
    meta: indexAkttmE9x3wMeta || {},
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/sports/index.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionAds",
    path: "/subscriptions/components/SectionAds",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/subscriptions/components/SectionAds.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionContent",
    path: "/subscriptions/components/SectionContent",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/subscriptions/components/SectionContent.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionDevices",
    path: "/subscriptions/components/SectionDevices",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/subscriptions/components/SectionDevices.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionDownload",
    path: "/subscriptions/components/SectionDownload",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/subscriptions/components/SectionDownload.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionHeader",
    path: "/subscriptions/components/SectionHeader",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/subscriptions/components/SectionHeader.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionSubscriptions",
    path: "/subscriptions/components/SectionSubscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/subscriptions/components/SectionSubscriptions.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions-components-SectionVoiceover",
    path: "/subscriptions/components/SectionVoiceover",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/subscriptions/components/SectionVoiceover.vue").then(m => m.default || m)
  },
  {
    name: "subscriptions",
    path: "/subscriptions",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "upcoming",
    path: "/upcoming",
    component: () => import("/srv/gitlab-runner/films/landing_redesign/landing-redesign-414457/src/pages/upcoming.vue").then(m => m.default || m)
  }
]